<template>
  <div>
    <div class="content">
      <div class="cont_top">
        <img src="~@/assets/icon/zsk.png" />
        <div class="zs">知识服务</div>
        <el-input v-model="valueData" placeholder="请输入关键词" @keyup.enter.native="handsearch()">
          <i
            class="el-input__icon el-icon-search"
            slot="suffix"
            style="cursor:pointer;"
          >
          </i>
        </el-input>
        <el-button class="js" @click="handsearch()">检索</el-button>
        <el-button class="downa" @click="ptdown()">上传</el-button>
      </div>
      <div class="label">
        <el-checkbox v-model="checked_name">数据库名</el-checkbox>
        <el-checkbox v-model="checked_sj">数据商</el-checkbox>
        <el-checkbox v-model="checked_mf">免费</el-checkbox>
        <el-checkbox v-model="checked_ff">付费</el-checkbox>
      </div>
    </div>
    <div class="table">
      <div class="head" v-show="tabnu">共{{ tableData.length }}条数据</div>
      <el-table
        :data="tableData"
        style="width: 100%"
        v-show="tabnu"
        @row-click="clickData"
      >
        <el-table-column prop="dbName" label="数据库名称" width="180">
        </el-table-column>
        <el-table-column prop="dbProvider" label="数据提供商" width="180">
        </el-table-column>
        <el-table-column prop="chargeType" label="收费类型"> </el-table-column>
        <el-table-column prop="resourceNum" label="资源量"> </el-table-column>
        <el-table-column prop="uploadTime" label="上传时间" :formatter="setTime"> </el-table-column>
      </el-table>
    </div>
    <img
      v-show="!tabnu"
      src="@/assets/img/组1163.png"
      style="width:100%"
      id="flash-bin"
    />
    <el-dialog
      title="知识服务合约"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <p class="hyl">1.合约基础条款：</p>
      <p>
        &nbsp;
        &nbsp;同的条款是合同中经双方当事人协商一致、规定双方当事人权利义务的具体条文。合同的条即合同的内容。合同的权利义务，除法律规定的以外，主要由合同的条款确定。合同的条款是否齐备、准确，决定
        了合同能否成立、生效以及能否顺利地履行、实现订立合同的目的。
      </p>
      <p class="hyl">2.合约基本规则：</p>
      <p>
        &nbsp; &nbsp;
        永续合约与传统的期货合约相似,是传统期货合约的一个衍生产品。无论是熊市或者牛市,用户都可以在永续合约中通过做多或做空来实现盈利。用户需要自己指定下单的价格和数量。限价委托规定了用户愿意买
        的最高价格或愿意卖的最低价格。
      </p>
      <div class="qy">
        <span class="qyfw">甲方：平台智荟网</span>
        <span class="qyfw">乙方：{{ this.$store.state.userinfo.name }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :disabled="disable" @click="agree()">{{
          getCode
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { INSERT, QUERY, DELETE, UPDATE, QUERYED } from "@/services/dao.js";
export default {
  data() {
    return {
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
      valueData: "",
      checked_name: true,
      checked_sj: false,
      tabnu: true,
      checked_mf: false,
      checked_ff: false,
      dialogVisible: false,
      getCode: "同意",
      count: 10,
      disable: true,
    };
  },
  created() {
    this.init();
  },

  methods: {
    setTime(e){
      let date = new Date(e.uploadTime||0)
      let Y = date.getFullYear()
      let M = date.getMonth()+1
      let D = date.getDate()
      // let h = date.getHours()
      // let m = date.getMinutes()
      // let s = date.getSeconds()
      return Y+'-'+M+'-'+D   //+' '+h+':'+m+":"+s
    },
    async init() {
      let result = await QUERYED(
        "post",
        "",
        "dbInformation { id dbName dbProvider chargeType resourceNum uploadTime }"
      );

      for (let i = 0; i < result.data.dbInformation.length; i++) {
        if (result.data.dbInformation[i].chargeType == "0") {
          result.data.dbInformation[i].chargeType = "免费 ";
        } else {
          result.data.dbInformation[i].chargeType = "付费 ";
        }
      }
      this.tableData = result.data.dbInformation;
      if (this.tableData.length > 0) {
        this.tabnu = true;
      } else {
        this.tabnu = false;
      }
    },
    async handsearch() {
      let AllData = [];
      if (this.checked_name == true) {
        let info = await QUERYED(
          "post",
          "",
          'dbInformation( where: {dbName: {_like: "%' +
            this.valueData +
            '%"}},offset: ' +
            0 +
            ") {id dbName dbProvider chargeType resourceNum uploadTime }"
        );
        AllData = AllData.concat(info.data.dbInformation);
      }
      if (this.checked_sj == true) {
        let info_sj = await QUERYED(
          "post",
          "",
          'dbInformation( where: {dbProvider: {_like: "%' +
            this.valueData +
            '%"}},offset: ' +
            0 +
            ") {id dbName dbProvider chargeType resourceNum uploadTime }"
        );
        AllData = AllData.concat(info_sj.data.dbInformation);
      }
      if (this.checked_mf == true && this.checked_ff == false) {
        let info_mf = await QUERYED(
          "POST",
          "",
          "dbInformation(where:{chargeType:{_eq:" +
            0 +
            "}}) {id dbName dbProvider chargeType resourceNum uploadTime}"
        );
        AllData = AllData.concat(info_mf.data.dbInformation);
      }
      if (this.checked_ff == true && this.checked_mf == false) {
        let info_ff1 = await QUERYED(
          "POST",
          "",
          "dbInformation(where:{chargeType:{_eq:" +
            1 +
            "}}) {id dbName dbProvider chargeType resourceNum uploadTime}"
        );
        let info_ff2 = await QUERYED(
          "POST",
          "",
          "dbInformation(where:{chargeType:{_eq:" +
            2 +
            "}}) {id dbName dbProvider chargeType resourceNum uploadTime}"
        );
        let info_ff3 = await QUERYED(
          "POST",
          "",
          "dbInformation(where:{chargeType:{_eq:" +
            3 +
            "}}) {id dbName dbProvider chargeType resourceNum uploadTime}"
        );
        AllData = AllData.concat(
          info_ff1.data.dbInformation.concat(
            info_ff2.data.dbInformation.concat(info_ff3.data.dbInformation)
          )
        );
      }
      var temp = {}; //用于name判断重复
      var result = []; //最后的新数组

      AllData.map(function(item, index) {
        if (!temp[item.id]) {
          result.push(item);
          temp[item.id] = true;
        }
      });
      console.log(result);
      for (let i = 0; i < result.length; i++) {
        if (result[i].chargeType == "0") {
          result[i].chargeType = "免费 ";
        } else {
          result[i].chargeType = "付费 ";
        }
      }
      this.tableData = result;
      if (this.tableData.length > 0) {
        this.tabnu = true;
      } else {
        this.tabnu = false;
      }
    },
    clickData(row) {
      this.$router.push({
        path: "/Knowledge_introduction",
        query: {
          id: row.id,
        },
      });
    },

    async ptdown() {
      let uid = this.$store.state.userinfo.id;

      let info = await QUERYED(
        "post",
        "",
        'contract( where: {uid: {_like: "%' +
          uid +
          '%"},contractType: {_like: "%' +
          0 +
          '%"}},offset: ' +
          0 +
          ") { id   }"
      );
      if (info.data.contract.length > 0) {
        this.dialogVisible = false;
        this.$router.push({
          path: "/Knowledge_down",
        });
      } else {
        this.getVerifyCode();
        this.dialogVisible = true;
      }
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    getVerifyCode() {
      var countDown = setInterval(() => {
        if (this.count < 1) {
          this.disable = false;
          this.getCode = "同意";
          this.count = 10;
          clearInterval(countDown);
        } else {
          this.disable = true;
          this.getCode = "同意(" + this.count-- + "s)";
        }
      }, 1000);
    },
    async agree() {
      let timestamp = Date.parse(new Date());
      let info = await INSERT(
        "POST",
        "",
        'insert_contract(objects: {contractTitle : "' +
          "知识服务条约" +
          '", contractContent : "' +
          0 +
          '", contractTime : "' +
          timestamp +
          '",effectiveYear : "' +
          1 +
          '",divided : "' +
          1 +
          '",price  : "' +
          10 +
          '",vendorName  : "' +
          this.$store.state.userinfo.name +
          '",vendorPhone  : "' +
          this.$store.state.userinfo.phone +
          '",ftpAddress  : "' +
          this.$store.state.userinfo.name +
          '",uid   : "' +
          this.$store.state.userinfo.id +
          '",contractType  : "' +
          0 +
          '",endTime : "' +
          timestamp +
          '",rootpath  : "' +
          0 +
          '", }) {    affected_rows  returning{id}   }'
      );
      this.dialogVisible = false;
      this.$router.push({
        path: "/Knowledge_down",
      });
    },
  },
};
</script>

<style scoped>
.content {
  background-image: url("~@/assets/cross_detect_bkgd.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 150px 0px 150px 300px;
}
.cont_top {
  overflow: hidden;
}
.content img {
  width: 50px;
  height: 50px;
  float: left;
}
.zs {
  font-size: 25px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #fefffd;
  float: left;
  line-height: 50px;
}
.el-input {
  width: 45%;
  margin-left: 3%;
}

.content >>> .el-input__inner {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.js {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.downa {
  width: 9%;
  color: #1b68c5;
  font-weight: 700;
}
.label {
  margin-left: 18%;
  margin-top: 10px;
}
.content >>> .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #fdfdfd;
}
.el-checkbox {
  color: #fdfdfd;
}
.el-checkbox {
  margin-right: 50px;
}
.table {
  border-top: 8px solid #6887c7;
  padding: 0px 200px 0px 200px;
}
.head {
  float: right;
  color: #146ac4;
  margin: 10px;
}
.el-dialog__wrapper >>> .el-dialog__footer {
  padding: 10px 20px 20px;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.el-dialog__wrapper >>> .el-dialog {
  background-color: #f6f6f6;
}

.hyl {
  font-weight: 800;
}
.qy {
  overflow: hidden;
  margin-top: 50px;
}
.qyfw {
  margin-left: 150px;
  font-weight: 800;
}
</style>
